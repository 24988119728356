.header {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;

  * {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .showSearch {
    display: block !important;
  }

  &__video {
    left: 0;
    top: 0;
    width: 100% !important;
    height: 100vh !important;

    @media (max-width: 575px) {
      height: 55vh !important;
    }

    video {
      object-fit: cover;
    }

    iframe {
      object-fit: cover;
      width: 100%;
    }
  }

  .logoHeader {
    display: none;
  }

  .alwaysFixed {
    position: fixed !important;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999999;

    @media (min-width: 1024px) {
      &:hover .hoverLogoBox {
        display: block;

        a {
          &:first-child {
            display: block;
          }

          &:last-child {
            display: none;
          }
        }
      }
    }
  }

  .fixedTop {
    &:hover .logoFixed {
      display: block;

      a {
        &:first-child {
          display: block;
        }

        &:last-child {
          display: none;
        }
      }
    }
  }

  &__navBox {
    position: relative;
    height: 94px;
    // background-color: #be9c6b;
    display: flex;
    justify-content: flex-end;
    z-index: 222;
    transition: all 0.3s;
    border-bottom: 1px solid rgba(161, 173, 197, 0.1);

    @media (min-width: 1025px) {
      &:hover {
        background-color: #fff !important;
        transition: all 0.3s !important;
        -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75) !important;
        -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75) !important;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75) !important;

        .header__searchBox {
          button {
            img {
              display: none !important;
            }
          }

          .noHover {
            display: block !important;
          }
        }

        a {
          color: #303882 !important;

          &:after {
            background-color: #303882 !important;
          }
        }
      }
    }

    @media (max-width: 1038px) {
      height: 96px;
    }

    @media (max-width: 1032px) {
      // display: none;
    }

    a {
      &:hover {
        color: #303882 !important;
      }

      &.active {
        color: #303882;
        font-weight: bold;
      }
    }
  }

  &__title {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 5555;
    left: 38px;

    @media (max-width: 1032px) {
      top: unset;
      transform: unset;
      bottom: 40px;
    }

    @media (max-width: 575px) {
      display: none;
    }

    h1 {
      color: #a17a4f;
      font-weight: 400;
      font-size: 40px;

      @media (max-width: 1100px) {
        font-size: 25px;
      }

      @media (max-width: 575px) {
        font-size: 18px;
      }
    }

    p {
      color: #fff;
      font-size: 60px;
      @media (max-width: 1100px) {
        font-size: 35px;
      }

      @media (max-width: 575px) {
        font-size: 20px;
      }
    }

    button {
      position: relative;
      font-size: 14px;
      background-color: transparent;
      color: #fff;
      border: none;
      margin-left: 85px;

      &:after {
        position: absolute;
        content: "";
        left: -80px;
        width: 70px;
        height: 2px;
        background-color: #a17a4f;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }
  }

  &__layout {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  &__logo {
    position: absolute;
    left: 34px;
    top: 9px;
    z-index: 223;
    width: 103px;

    @media (max-width: 1250px) {
      width: 112px;
    }

    @media (max-width: 1032px) {
      width: 97px !important;
    }

    @media (max-width: 575px) {
      width: 82px;
      left: 20px;
      width: 63px !important;
      top: 25px;
    }

    img {
      width: 100%;
    }
  }

  &__nav {
    display: flex;
    align-items: center;

    @media (max-width: 765px) {
      // padding-right: 9px;
    }
  }

  &__navLeft {
    margin-right: 50px;

    @media (max-width: 1032px) {
      display: none;
    }

    a {
      position: relative;
      color: #fff;
      font-size: 15px;
      transition: all 0.2s;
      text-transform: uppercase;

      &:after {
        position: absolute;
        content: "";
        left: 0;
        bottom: -5px;
        width: 0%;
        height: 2px;
        background-color: #303882;
        transition: all 0.4s cubic-bezier(0.63, 0.18, 0, 1.03);
      }

      &:hover {
        &:after {
          transition: all 0.4s cubic-bezier(0.63, 0.18, 0, 1.03);
          width: 100%;
        }
      }

      &.active {
        color: #fff;
        font-weight: bold;

        &:after {
          transition: all 0.4s cubic-bezier(0.63, 0.18, 0, 1.03);
          width: 100%;
          background-color: #ffffff;
        }
      }

      @media (max-width: 1312px) {
        font-size: 13px;
      }

      &:hover {
        transition: all 0.2s;
        color: #a17a4f;
      }

      &:not(:last-child) {
        margin-right: 40px;

        @media (max-width: 1200px) {
          margin-right: 18px;
        }
      }
    }
  }

  &__mobMenu {
    display: none;

    @media (max-width: 1032px) {
      display: block;
      margin-right: 25px;

      svg {
        color: #fff;
        font-size: 30px;
      }
    }
  }

  &__navRight {
    display: flex;
    align-items: center;
  }

  &__langBox {
    margin-right: 21px;

    a {
      position: relative;
      color: #fff !important;
      font-size: 14px;
      text-transform: uppercase;
      transition: all 0.2s;

      svg {
        font-size: 20px;
      }

      &:before {
        position: absolute;
        content: "";
        left: 0;
        bottom: -5px;
        width: 0%;
        height: 2px;
        background-color: #303882;
        transition: all 0.4s cubic-bezier(0.63, 0.18, 0, 1.03);
      }

      &:hover {
        &:before {
          transition: all 0.4s cubic-bezier(0.63, 0.18, 0, 1.03);
          width: 100%;
        }
      }

      &:not(:last-child) {
        margin-right: 15px;

        &:after {
          position: absolute;
          content: "";
          right: -7px;
          top: 0px;
          width: 1px;
          height: 70%;
          background-color: #fff;
          top: 50%;
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          transform: translateY(-50%);
        }
      }
    }
  }

  &__searchBox {
    width: 94px;
    height: 95px;
    // background-color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 575px) {
      width: 51px;
      height: 51px;
    }

    svg {
      color: #000;
      font-size: 22px;
    }

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }

  &__closeMenu {
    position: absolute;
    right: -36px;
    top: -49px;
    font-size: 30px;
    color: #fff;
    cursor: pointer;
  }

  .noHover {
    display: none;
  }

  &__mobDropMenu {
    .header__closeMenu {
      right: 9px;
      top: 3px;
    }

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999999;
    background: #2c3478;
    display: none;
    padding: 15px;
    padding-top: 60px;
    transition: all 0.3s;

    @media (max-width: 1032px) {
      white-space: nowrap;
      display: block !important;
      flex-wrap: nowrap;
      max-width: 100%;
      overflow-y: scroll;
    }

    .header__navLeft {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-right: 0;

      a {
        font-size: 21px;
        margin-right: 0;
        text-align: center;

        &:hover {
          color: #303882;
        }

        margin-bottom: 25px;
      }
    }
  }

  .showMenu {
    transform: translateX(-150%);
    transition: all 0.3s;
  }

  &__end {
    position: absolute;
    bottom: 30px;
    width: 100%;
    background-color: transparent;
    z-index: 1;

    @media (max-width: 1100px) {
      display: none;
    }

    .splide__track {
      height: 50px;

      &:hover {
        height: 300px;
      }

      .splide__list {
        position: absolute;
        bottom: 4px;
      }
    }
  }

  &__endInfobox {
    position: relative;
    transition: all 0.2s;
    text-align: center;

    a {
      position: relative;
      color: #fff !important;
      font-size: 15px;
      white-space: nowrap;
      text-transform: uppercase;

      &:after {
        position: absolute;
        content: "";
        left: 0;
        top: unset;
        bottom: -5px;
        width: 100%;
        height: 2px;
        background-color: #fff;
        transition: all 0.4s cubic-bezier(0.63, 0.18, 0, 1.03);
      }

      &:hover {
        &:after {
          transition: all 0.4s cubic-bezier(0.63, 0.18, 0, 1.03);
          transform: scale(1.3);
        }
      }
    }

    &:not(:last-child) {
      margin-right: 150px;
    }

    &:last-child {
      .header__endDropBox {
        left: 0px;
      }
    }

    &:hover .header__endDropBox {
      transition: all 0.2s;
      bottom: 22px;
      z-index: 5;
      visibility: visible;
      opacity: 1;
    }
  }

  &__endDropBox {
    visibility: hidden;
    position: absolute;
    background-color: #2c3478;
    min-width: 264px;
    left: 0;
    z-index: -5;
    bottom: 33px;
    transition: all 0.1s;
    opacity: 0;

    &--title {
      margin-top: 15px;
      min-height: 50px;

      h4 {
        text-align: center;
        color: #fff;
        margin-bottom: 0;
        font-size: 15px;
      }
    }

    &--info {
      padding: 0 20px;
      padding-bottom: 20px;

      a {
        &:after {
          position: absolute;
          content: "";
          left: 0;
          top: unset;
          bottom: -5px;
          width: 0%;
          height: 2px;
          background-color: #fff;
          transition: all 0.4s cubic-bezier(0.63, 0.18, 0, 1.03);
        }

        &:hover {
          &:after {
            transition: all 0.4s cubic-bezier(0.63, 0.18, 0, 1.03);
            width: 100%;
          }

          transition: all 0.2s;
          color: #fff;
        }
      }

      p {
        color: #fff;
        font-size: 15px;
        text-align: left;
        margin-top: 15px !important;
      }
    }

    .vieMoreContent {
      display: flex;
      justify-content: flex-end;
    }
  }

  .playVideo {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 555;
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
    padding: 5px 10px;
    display: none;

    @media (max-width: 575px) {
      display: block;
    }
  }

  // splide slider
  &__slider {
    position: relative;
    height: 100vh;

    @media (max-width: 575px) {
      height: 55vh;
    }

    img {
      height: 100vh;
    }

    .splide__pagination {
      right: 0;
      left: unset;
      transform: unset;
      width: 59px;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .splide__pagination__page {
      border-radius: 0;
      height: 53px;
      width: 5px;
      background-color: #303882;
      opacity: 1;

      @media (max-width: 765px) {
        height: 20px;
      }
    }

    .splide__pagination li {
      position: relative;
      width: 5px;
      background: transparent;
      opacity: 1;

      .is-active {
        transform: unset;
        background-color: #ffffff;
      }

      &:not(:last-child) {
        margin-bottom: 2px;
      }
    }
  }
}

.fixedTop {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 99999;
}

.logoFixed {
  display: none;
}

.changeLangBox {
  a {
    color: #303882 !important;

    &:after {
      background-color: #303882 !important;
    }
  }
}

.overflowHiddenMenu {
  height: 143px;
  overflow: hidden;
  display: -webkit-box;
  line-height: 14px;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.translateWrapper {
  display: none;
  top: 18px;
  background: #e4e3e3;
  padding: 11px;
}

.worldTranslate {
  &:hover .translateWrapper {
    display: block !important;
    position: absolute;
    right: -71px;
  }
}

.hoverScroll {
  background: #fff !important;

  .hoverLogoBox a:first-child {
    display: block;
  }

  .hoverLogoBox a:last-child {
    display: none;
  }

  .header__searchBox button img {
    display: none !important;
  }

  .header__searchBox .noHover {
    display: block !important;
  }

  a {
    color: #303882 !important;

    &:after {
      background-color: #303882 !important;
    }
  }
}

.alwaysFixedInline {
  &:hover .hoverLogoBox a {
    display: none !important;
  }

  &:hover .hoverLogoBox a:first-child {
    display: block !important;
  }
}

.hoverScrollInline {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;

  //background: #fff !important;
  //
  //.hoverLogoBox a:first-child {
  //  display: block;
  //}
  //
  //.hoverLogoBox a:last-child {
  //  display: none;
  //}
  //
  //.header__searchBox button img {
  //  display: none !important;
  //}
  //
  //
  //.header__searchBox .noHover {
  //  display: block !important;
  //}
  //
  //
  //a {
  //  color: #303882 !important;
  //
  //  &:after {
  //    background-color: #303882 !important;
  //  }
  //}
}
