.whatsap_div {
    position: fixed !important;
    bottom: 27px;
    right: 27px;
    width: 55px;
    cursor: pointer;
    z-index: 100;
    padding: 11px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    background-color: #2cb742;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-animation: pulse 1.6s infinite;
    -moz-animation: pulse 1.6s infinite;
    -o-animation: pulse 1.6s infinite;
    animation: pulse 1.6s infinite;
}

.whatsap_div img {
    width: 100%;
}

@keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgb(44 183 66 / 85%);
        -moz-box-shadow: 0 0 0 0 rgba(44, 183, 66, 0.85);
        box-shadow: 0 0 0 0 rgb(44 183 66 / 85%);
    }

    70% {
        -webkit-box-shadow: 0 0 0 2em rgb(44 183 66 / 0%);
        -moz-box-shadow: 0 0 0 2em rgba(44, 183, 66, 0);
        box-shadow: 0 0 0 2em rgb(44 183 66 / 0%);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgb(44 183 66 / 0%);
        -moz-box-shadow: 0 0 0 0 rgba(44, 183, 66, 0);
        box-shadow: 0 0 0 0 rgb(44 183 66 / 0%);
    }
}
