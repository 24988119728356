@font-face {
  font-family: 'FuturaExtended';
  src: url('../fonts/FuturaExtended.eot');
  src: url('../fonts/FuturaExtended.eot?#iefix') format('embedded-opentype'),
  url('../fonts/FuturaExtended.woff2') format('woff2'),
  url('../fonts/FuturaExtended.woff') format('woff'),
  url('../fonts/FuturaExtended.ttf') format('truetype'),
  url('../fonts/FuturaExtended.svg#FuturaExtended') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

