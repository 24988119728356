.footer {
  background-color: #303882;
  //padding-top: 56px !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media (max-width: 992px) {
    padding: 35px 20px !important;
  }

  &__socialFixed {
    position: sticky;
    left: 0;
    bottom: 300px;
    width: 50px;
    display: flex;
    flex-direction: column;

    @media (max-height: 650px) {
      bottom: 50px;
    }
  }

  &__socialItems {
    width: 50px;
    height: 50px;
    transition: all 0.2s;
    background: rgb(102, 102, 102);

    @media (max-width: 992px) {
      width: 27px;
      height: 27px;
    }

    &:hover {
      transition: all 0.2s;
      width: 70px;
    }

    a {
      display: block;
      height: 100%;
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      color: #fff;
      font-size: 20px;

      @media (max-width: 992px) {
        font-size: 15px;
      }
    }
  }

  hr {
    background-color: #e0eded;
    margin-top: 3rem;
  }

  &__reserv {
    padding-top: 0px !important;
    padding-bottom: 10px !important;

    p {
      color: #fff;
      text-align: center;
    }
  }

  @media (max-width: 575px) {
    padding-bottom: 30px !important;
    padding-top: 30px !important;
  }

  &__flexBoxItems {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;

    @media (max-width: 724px) {
      flex-direction: column;
    }
  }

  &__left {
    width: 50%;
    display: flex;
    justify-content: start;
    flex-direction: column;

    @media (max-width: 992px) {
      margin-bottom: 25px;
      width: 100%;
    }

    > img {
      max-width: 120px;

      @media (max-width: 1100px) {
        width: 103px;
      }
    }
  }

  &__flexBox {
    display: flex;
    padding-top: 27px;

    @media (max-width: 992px) {
      flex-direction: column;
      padding-top: 0;
    }
  }

  &__social {
    margin-top: 24px;

    a {
      display: inline-block;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    svg {
      color: #fff;

      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }

  &__right {
    display: flex;
    justify-content: space-between;
    width: 50%;
    // padding-top: 103px;

    @media (max-width: 992px) {
      display: block;
      width: 100%;
    }

    &--items {
      width: calc(100% / 2);
      padding-right: 30px;

      > div {
        width: 100% !important;
      }

      @media (max-width: 992px) {
        float: left;
      }

      &:not(:last-child) {
        margin-left: 50px !important;

        @media (max-width: 1366px) {
          margin-right: 92px;
          margin-left: 0 !important;
        }

        @media (max-width: 1162px) {
          margin-right: 45px;
        }

        @media (max-width: 992px) {
          width: calc((100% / 2) - 23px);
        }

        @media (max-width: 575px) {
          width: 100%;
          margin-top: 15px;
        }
      }

      &:nth-child(2) {
        margin-right: 0 !important;
      }

      &:last-child {
        @media (max-width: 992px) {
          width: 100%;
          margin-top: 30px;
        }

        h4 {
          text-align: left;

          @media (max-width: 992px) {
            text-align: left;
          }
        }

        p {
          color: #fff;
          text-align: left;

          @media (max-width: 992px) {
            text-align: left;
          }
        }

        a {
          display: flex;
          justify-content: flex-start;
          white-space: nowrap;
          text-align: left;
          @media (max-width: 992px) {
            text-align: left;
            justify-content: flex-start;
          }

          @media (max-width: 575px) {
            font-size: 14px;
          }

          &:last-child {
            @media (max-width: 575px) {
              margin-bottom: 0;
            }
          }

          a {
            margin-left: 8px;
          }
        }
      }

      h4 {
        font-size: 20px;
        margin-bottom: 35px;
        color: #fff;
        white-space: nowrap;
      }

      a {
        display: block;
        color: #fff;
        transition: all 0.2s;
        white-space: nowrap;

        &:not(:last-child) {
          margin-bottom: 16px;
        }

        &:hover {
          color: #fff;
          padding-left: 5px;
        }
      }
    }
  }

  &__logoBox {
    max-width: 115px;
  }

  &__logoSelf {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 25px;
  }

  &__map {
    width: 300px;
    height: 229px;
    margin-top: 50px;

    @media (max-width: 992px) {
      display: none;
    }

    > div {
      height: 229px;
    }
  }

  &__container {
    position: relative;
    margin: 0 auto;
    max-width: 1500px;
  }

  &__twitter {
    padding-right: 70px;

    @media (max-width: 1400px) {
      padding-right: 0;
    }

    @media (max-width: 992px) {
      display: none;
    }
  }

  &__about {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 0;
  }

  &__terminal {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    @media (max-width: 992px) {
      justify-content: unset;
    }

    p {
      display: flex;
    }
  }
}
