@import "node_modules/react-modal-video/scss/modal-video.scss";

* {
  margin: 0;
  padding: 0;
  text-decoration: none !important;
  outline: none !important;
  font-family: "Roboto Condensed", sans-serif !important;
}

.vieMoreContentHeader {
  font-size: unset !important;

  &:hover {
    button {
      transition: all 0.2s;
      margin-left: 0;

      &:after {
        left: 84px;
      }
    }
  }
}

.btnViewMore {
  position: relative;
  font-size: 14px;
  background-color: transparent;
  color: #fff;
  border: none;
  transition: all 0.2s;

  &:after {
    position: absolute;
    transition: all 0.2s;
    content: "";
    width: 70px;
    left: -80px;
    height: 2px;
    background-color: #2c3478;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.myPad {
  padding: 0 38px;

  @media (max-width: 765px) {
    padding: 0 32px;
  }
}

.search {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(48, 56, 130, 0.8);
  z-index: 9999;
  display: none;

  &__flexBox {
    position: relative;
    display: flex;
    align-items: center;

    button {
      background-color: transparent;
      border: none;
      position: absolute;
      right: 15px;
    }
  }

  &__close {
    position: absolute;
    right: -29px;
    top: -42px;
    font-size: 30px;
    color: #fff;
    cursor: pointer;

    @media (max-width: 575px) {
      right: 0;
    }
  }

  &__inputBox {
    position: relative;
    width: 50%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    @media (max-width: 992px) {
      width: 74%;
    }

    @media (max-width: 575px) {
      width: 83%;
    }

    input {
      padding: 15px;
      width: 100%;
      height: 70px;
      border: none;
      border-radius: unset;

      color: #303882;
      font-weight: bold;
      font-size: 20px;

      &::placeholder {
        color: #303882;
        font-weight: bold;
        font-size: 20px;
      }
    }
  }
}

// loading component

.loadableCom {
  height: 700px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.load {
  width: 100px;
  height: 100px;
  margin: auto 0;
  border: solid 10px #303882;
  border-radius: 50%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  -webkit-transition: all 0.5s ease-in;
  -webkit-animation-name: rotate;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  transition: all 0.5s ease-in;
  animation-name: rotate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.product__info {
  a {
    &.active {
      color: #303882 !important;
    }
  }
}

.tit {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 15px;
  font-size: 25px;
  font-weight: bold;

  @media (max-width: 992px) {
    font-size: 15px;
  }
}

.viewMoreColor {
  button {
    color: #303882 !important;
  }
}

.splide__arrow {
  background: #303882 !important;
  opacity: 1 !important;

  svg {
    fill: #fff !important;
  }
}

.splide__arrow--prev {
  left: -3em !important;
}

.splide__arrow--next {
  right: -3em !important;
}

// .staticData{
//   margin-bottom: -160px;
// }

.splide__arrows {
  @media (max-width: 724px) {
    display: none;
  }
}

.accordion__button {
  background: #e8e8e8 !important;

  &.detail {
    background: #303882 !important;
  }

  padding: 30px 38px !important;

  h2 {
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 13px;
    color: #303882;

    &.detail {
      color: #ffffff;
    }
  }

  &:before {
    display: none !important;
  }

  .accordionFlex {
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #303882;
    display: flex;
    justify-content: space-between;

    .email {
      a {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #303882;

        &.detail {
          color: #fff;
        }
      }
    }

    span {
      margin-right: 20px;
      color: #303882;

      &.detail {
        color: #ffffff;
      }
    }
  }
}

.accordion {
  border: none !important;
}

.accordion__panel {
  padding: 24px 38px !important;

  &.applyTerms {
    padding: 24px 0 !important;
  }

  .applyTendersFlex {
    display: flex;
    justify-content: flex-end;
    margin-top: 60px;

    .applyTenders {
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      color: #ffffff;
      background: #303882;
      border-radius: 10px;
      display: inline-flex;
      justify-content: flex-end;
      width: 197px;
      height: 72px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  h3 {
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    color: #303882;
    margin-bottom: 24px;
  }

  p {
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    color: #4f4f4f;
  }
}

.homePageTender {
  margin: 50px 0;
}

.accordian__form {
  width: 80%;
}

.accordion__inputBox {
  display: flex;
  flex-wrap: wrap;

  input,
  div {
    background: #f7f7f7;
    border-radius: 10px;
    width: calc(50% - 35px);
    height: 68px;
    padding: 23px 31px;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #303882;
    margin-bottom: 16px;
    border: 1px solid #303882;

    &::placeholder {
      color: #303882;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      color: #303882;
    }

    &:nth-child(odd) {
      margin-right: 35px;
    }
  }
}

.accordion__applyFormBox {
  display: flex;
  justify-content: flex-end;
}

.Toastify {
  position: absolute;
  z-index: 99999999 !important;
}

.proporsal {
  position: relative;

  span {
    position: absolute;
    inset: 20px;
  }

  input {
    opacity: 0;
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    padding: 0px 31px;
  }
}

.accordion__panel {
  table {
    tr,
    td {
      border: 1px solid #000;
      padding: 20px;
    }
  }
}

.loader {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
}

.loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.loader div:nth-child(1) {
  animation-delay: -0.45s;
}

.loader div:nth-child(2) {
  animation-delay: -0.3s;
}

.loader div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.xls_download {
  font-size: 20px;
  color: #4f4f44 !important;
  display: flex;
  align-items: center;

  &:not(:last-child){
    margin-bottom: 20px;
  }

  img{
    margin-right: 5px;
    width: 25px;
  }
}
